



















import { filterNum } from "@/utils/utils";
import Vue from "vue";
export default Vue.extend({
  props: {
    speed: Number,
    tps: Number,
    txNumber: Number,
    addressNumber: Number,
  },
  computed: {
    list(): { name: string; value: string | number }[] {
      return [
        {
          name: this.$t("lang.chainPerf.speed") as string,
          value: this.speed + "s",
        },
        {
          name: this.$t("lang.chainPerf.tps") as string,
          value: 1000,
        },
        {
          name: this.$t("lang.chainPerf.txNumber") as string,
          value: filterNum(this.txNumber),
        },
        {
          name: this.$t("lang.chainPerf.addressNumber") as string,
          value: filterNum(this.addressNumber),
        },
      ];
    },
  },
});
