import { axios, iJrpcRet } from ".";

const scanApi = "/setScan";

export function getNodeNum(): Promise<
  iJrpcRet<{
    countries: {
      country: string;
      value: number;
    }[];
  }>
> {
  return axios(scanApi, {
    method: "post",
    params: {
      id: 1,
      jsonrpc: "1.0",
      method: "NetScan.PeersLocation",
      params: [],
    },
  });
}
