






















import Vue, { PropType } from "vue";
import mBlockItem from "./m-blockItem.vue";
import { iBlockMsg } from "ycc-api/dist/cmjs/service/home/types";

export default Vue.extend({
  components: { mBlockItem },
  props: {
    latestBlocks: Array as PropType<iBlockMsg[]>,
  },
});
