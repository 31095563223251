









































import VueTypedMixins from 'vue-typed-mixins'

import blockChainBrowserMixin from '@/mixin/blockChainBrowser'
import MChainSearch from '@/components/mobile/m-chainSearch.vue'
import MChainCard from '@/components/mobile/m-chainCard.vue'
import MBlueIntro from '@/components/mobile/m-blue-intro.vue'
import MNumOfOnChainTx from '@/components/mobile/map/m-numOfOnChainTx.vue'
import MChainPrice from '@/components/mobile/m-chain-price.vue'
import MChainPerf from '@/components/mobile/m-chain-perf.vue'
import MLatestBlocks from '@/components/mobile/m-latestBlocks.vue'
import MLatestTx from '@/components/mobile/m-latestTx.vue'

export default VueTypedMixins(blockChainBrowserMixin).extend({
  components: {
    MChainSearch,
    MChainCard,
    MBlueIntro,
    MNumOfOnChainTx,
    MChainPrice,
    MChainPerf,
    MLatestBlocks,
    MLatestTx,
  },
})
