


























import Vue, { PropType } from 'vue'
import mTxItem from './m-txItem.vue'
export default Vue.extend({
  components: { mTxItem },
  props: {
    latestTxs: Array as PropType<any[]>,
  },
})
