










import VueTypedMixins from 'vue-typed-mixins'
import txt from '@/assets/images/blockChainBrowser/home/yccText.png'
export default VueTypedMixins().extend({
  data() {
    return {
      txt,
    }
  },
})
