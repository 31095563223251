var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"num flow-root"},[_c('div',{staticClass:"mt-2.5 flex items-center justify-between"},[_c('div',{staticClass:"text-darkBlue font-bold"},[_vm._v(" "+_vm._s(_vm.$t('lang.chainPerf.txNumberOnTheChain'))+" ")]),_c('div',_vm._l((_vm.filters),function(filter,i){return _c('span',{key:i,staticClass:"text-text-color",class:{
          'text-darkBlue': _vm.currentFilter === filter,
          'font-bold': _vm.currentFilter === filter,
        },style:({
          'margin-left': i !== 0 && '15px',
        }),on:{"click":function($event){_vm.currentFilter = filter}}},[_vm._v(" "+_vm._s(filter)+" ")])}),0)]),_c('charts',{staticStyle:{"height":"100px"},attrs:{"scale":_vm.currentFilter,"grid":{
      top: '12px',
      bottom: '25px',
      left: '50px',
      right: '20px',
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }