import Vue from "vue";
import { getLatestBlock } from "ycc-api/dist/cmjs/service/home/index";
import { iBlockMsg } from "ycc-api/dist/cmjs/service/home/types";
import { getRpc } from "ycc-api/src/service/Rpc";
import RPC from '../service/chain33rpc'
import Base64 from "@/utils/_base64";
import moment from "moment";

import {
  blockStatStatGets,
  getAccountCount,
  getAllPos33TicketCount,
  getTxCountBewteenTimes,
  getTxList,
  yccApi,
} from "@/service/api";

import { icustomizedTx } from "@/types/trade";
import { getNodeNum } from "@/service/blockChainBrowser";
import {
  clearTimeoutFromTimerObj,
  registerTimeoutToTimerObj,
} from "@/utils/common";

const TimerObj = {} as { [key: string]: number };
const __timeout = 10 * 1e3;

export default Vue.extend({
  data() {
    return {
      latestHeight: NaN,
      totalTx: NaN,
      value: "",
      latestBlocks: new Array(6).fill({}),
      quoteChange: "",
      price: "",
      marketValue: 0,
      speed: 0,
      tps: 0,
      addressNumber: 0,
      latestTxs: [] as icustomizedTx[],
      ticketCount: 0,
      timer: 0,
      nodeNum: 0,
    };
  },
  mounted() {
    this.fetchAllPos33TicketCountData();
    this.fetchAccountCountData();
    this.fetchTpsData();
    this.init();
    this.fetchLatestTxListData();
    this.fetchNodeNum();
  },
  beforeDestroy() {
    clearTimeoutFromTimerObj(TimerObj);
  },
  methods: {
    fetchNodeNum() {
      getNodeNum().then((res) => {
        if (res.error === null) {
          this.nodeNum = (res.result.countries || []).reduce((prev, cur) => {
            return prev + cur.value;
          }, 0);
          registerTimeoutToTimerObj(TimerObj, this.fetchNodeNum, __timeout);
        }
      });
    },
    fetchTpsData() {
      getTxCountBewteenTimes(
        moment().unix(),
        moment()
          .add(24, "hour")
          .unix()
      ).then((res) => {
        if (res.error === null) {
          this.tps = Number((res.result / (24 * 60 * 60)).toFixed(2));
          registerTimeoutToTimerObj(TimerObj, this.fetchTpsData, __timeout);
        }
      });
    },
    fetchLatestTxListData() {
      getTxList(1, 6).then((ret) => {
        if (!ret.error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.latestTxs = ret.result.map((i: any) => ({
            ...i,
            hash: i.hash,
            from: i.from,
            to: i.to,
            time_block: i.block_time,
            amount: i.amount,
            success: i.success,
          }));
          registerTimeoutToTimerObj(
            TimerObj,
            this.fetchLatestTxListData,
            __timeout
          );
        }
      });
    },
    fetchAccountCountData() {
      getAccountCount().then((ret) => {
        if (ret.error === null) {
          this.addressNumber = ret.result;
          registerTimeoutToTimerObj(
            TimerObj,
            this.fetchAccountCountData,
            __timeout
          );
        }
      });
    },
    fetchAllPos33TicketCountData() {
      getAllPos33TicketCount().then((ret) => {
        if (ret.error === null) {
          this.ticketCount = ret.result.all_count || 0;
          registerTimeoutToTimerObj(
            TimerObj,
            this.fetchAllPos33TicketCountData,
            __timeout
          );
        }
      });
    },
    getSpeed() {
      blockStatStatGets(725000, this.latestHeight).then((res) => {
        if (res.error === null) {
          const fatherBlock = res.result[0];
          const lastBlock = res.result[1];
          const perTime =
            (lastBlock.time - fatherBlock.time) / (this.latestHeight - 725000);
          this.speed = Number(perTime.toFixed(2));
        }
      });
    },
    setValue(v: string) {
      this.value = v;
    },
    clickSearch(value: string) {
      console.log(value, "show value");
    },
    async getLatestBlockData() {
      let blocks = [] as any
      const res = await getLatestBlock("ycc", yccApi)
      const newHeight = (res as any)[0].height
      const { result } = await RPC.getHeaders(newHeight - 5, newHeight, false)
      const hashes = result.items.reverse()
      blocks = res?.map((item, index) => {
        return { ...item, ...hashes[index] }
      })
      this.latestBlocks = blocks
    },
    hexCharCodeToStr(hexCharCodeStr: string) {
      const num = hexCharCodeStr;
      const trimedStr = num.trim();
      const rawStr =
        trimedStr.substr(0, 2).toLowerCase() === "0x"
          ? trimedStr.substr(2)
          : trimedStr;
      const len = rawStr.length;
      if (len % 2 !== 0) {
        alert("Illegal Format ASCII Code!");
        return "";
      }
      let curCharCode;
      const resultStr = [];
      for (let i = 0; i < len; i = i + 2) {
        curCharCode = parseInt(rawStr.substr(i, 2), 16); // ASCII Code Value
        resultStr.push(String.fromCharCode(curCharCode));
      }
      //转码ASCII——base64
      const b = new Base64();
      const str = b.encode(`TotalFeeKey:${resultStr.join("")}`);
      return str;
    },
    async init() {
      this.getLatestBlockData();

      const lastHeaderRet = await getRpc(yccApi).getLastHeader();
      if (!lastHeaderRet.error) {
        this.latestHeight = lastHeaderRet.result.height;
        this.$store.commit("saveMaxHeight", this.latestHeight);
        const str = this.hexCharCodeToStr(lastHeaderRet.result.hash);
        this.getSpeed();
        fetch("/market")
          .then((ret) => ret.json())
          .then((ret) => {
            if (ret.msg === "succeed") {
              const data = ret.data.data.USDT.YCC;
              this.price = data.last;
              this.quoteChange = data.range;
              this.marketValue = Math.floor(
                Number(data.last) *
                (100 * 1e8 + 30 * 725000 + 15 * (this.latestHeight - 725000))
              );
            }
          });

        const totalFeeRet = await getRpc(yccApi).queryTotalFee([str]);
        if (!totalFeeRet.error) {
          this.totalTx = totalFeeRet.result.txCount;
          registerTimeoutToTimerObj(TimerObj, this.init, __timeout);
        }
      }
    },
  },
  computed: {
    circulationNum(): number {
      // 100 亿+30*725000 + 15*（当前高度-725000）
      return 100 * 1e8 + 30 * 725000 + 15 * (this.latestHeight - 725000);
    },
    msgList(): { icon: string; title: string; number: number }[] {
      return [
        {
          icon: require("@/assets/images/blockChainBrowser/home/latestBlock.png"),
          title: this.$t("lang.chainMsg.latestBlock") as string,
          number: this.latestHeight,
        },
        {
          icon: require("@/assets/images/blockChainBrowser/home/nodeNumber.png"),
          title: this.$t("lang.chainMsg.nodeNum") as string,
          number: this.nodeNum,
        },
        {
          icon: require("@/assets/images/blockChainBrowser/home/circulateNumber.png"),
          title: this.$t("lang.chainMsg.circulation") as string,
          number: this.circulationNum,
        },
        {
          icon: require("@/assets/images/blockChainBrowser/home/pledgeQuantity.png"),
          title: this.$t("lang.chainMsg.pledgeQuantity") as string,
          number: this.ticketCount,
        },
      ];
    },
  },
});
