




































import Vue from 'vue'
import bg from '@/assets/images/blockChainBrowser/home/beautiful-rect.png'
import yccIcon from '@/assets/images/blockChainBrowser/home/ycc-icon.png'
export default Vue.extend({
  props: {
    price: String,
    quoteChange: String,
    marketValue: Number,
  },
  data() {
    return {
      bg,
      yccIcon,
    }
  },
})
