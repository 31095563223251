



















import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    msgList: Array as PropType<
      {
        icon: string
        title: string
        number: number
      }[]
    >,
  },
})
