




import Vue from "vue";
import * as echarts from "echarts/core";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { numOfOnChainTx } from "@/service/api";
import moment from "moment";
import { nFormatter } from "@/utils/common";

type ECOption = echarts.ComposeOption<LineSeriesOption>;

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer]);

export default Vue.extend({
  props: {
    scale: String,
    grid: Object,
  },
  data() {
    return {
      xData: [],
      seriesData: [],
    };
  },
  methods: {
    generateD1() {
      const list = [];
      for (let i = 1; i < 8; ++i) {
        const m = moment().startOf("days").add(-i, "days");
        list.push(m);
      }

      return list.map((i) => {
        return {
          start: i.unix(),
          format: i.format("YYYY.MM.DD"),
          end: i.endOf("days").unix(),
        };
      });
    },
    generateW1() {
      const list = [];
      for (let i = 1; i < 8; ++i) {
        const m = moment()
          .hour(0)
          .minute(0)
          .second(0)
          .add(-i * 7, "days");
        list.push(m);
      }

      return list.map((i) => {
        return {
          start: moment(i).add(-1, "weeks").unix(),
          end: moment(i).unix(),
          format: `${moment(i).add(-1, "weeks").format("MMMDD")} - ${moment(
            i
          ).format("MMMDD")}`,
        };
      });
    },
    generateM1() {
      const list = [];
      for (let i = 1; i < 8; ++i) {
        const m = moment().startOf("month").add(-i, "month");
        list.push(m);
      }
      return list.map((i) => {
        return {
          start: moment(i).unix(),
          end: moment(i).endOf("month").unix(),
          format: `${moment(i).format("MMM")}`,
        };
      });
    },
    generateY1() {
      const list = [];
      for (let i = 0; i < 7; ++i) {
        const m = moment().hour(0).minute(0).second(0).add(-i, "year");
        list.push(m);
      }
      return list.map((i) => {
        return {
          start: i.startOf("year").unix(),
          end: i.endOf("year").unix(),
          format: `${i.format("YYYY")}`,
        };
      });
    },
    generate() {
      switch (this.scale) {
        case "D1":
          return this.generateD1();
        case "W1":
          return this.generateW1();
        case "M1":
          return this.generateM1();
        case "Y1":
          return this.generateY1();
      }
    },
    draw() {
      // 接下来的使用就跟之前一样，初始化图表，设置配置项
      // filters: ['D1', 'W1', 'M1', 'Y1'],
      const times = this.generate();
      times &&
        numOfOnChainTx(times).then((ret) => {
          if (ret.error == null) {
            this.xData = ret.result;
            var myChart = echarts.init(this.$refs.charts as HTMLElement);
            myChart.setOption({
              tooltip: {
                trigger: "axis",
              },
              xAxis: {
                type: "category",
                axisLabel: {
                  textStyle: {
                    color: "#7c88ad",
                  },
                },
                axisTick: {
                  show: false,
                },
                data: times
                  ?.map((i) => {
                    return i.format;
                  })
                  .reverse(),
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  formatter: (item: number) => {
                    return nFormatter(item);
                  },
                  textStyle: {
                    color: "#7c88ad",
                  },
                },
              },
              series: [
                {
                  type: "line",
                  data: this.xData.map((i: any) => i || 0).reverse(),
                  areaStyle: {
                    color: "red",
                    normal: {
                      // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#2545CB", // 0% 处的颜色
                        },
                        {
                          offset: 0.6,
                          color: "#9FAEE9", // 40% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "white", // 100% 处的颜色
                        },
                      ]), //背景渐变色
                      lineStyle: {
                        // 系列级个性化折线样式
                        type: "solid",
                        color: "#2545CB", //折线的颜色
                      },
                    },
                  },
                  smooth: true,
                  showSymbol: false,
                  itemStyle: {},
                },
              ],
              grid: { ...this.grid },
            } as ECOption);
          }
        });
    },
  },
  mounted() {
    this.draw();
  },
  watch: {
    scale(v) {
      this.draw();
    },
    "$route.query.lang"() {
      this.draw();
    },
  },
});
