





































import charts from '@/components/charts.vue'
import Vue from 'vue'
export default Vue.extend({
  components: { charts },
  data() {
    return {
      filters: ['D1', 'W1', 'M1', 'Y1'],
      currentFilter: 'D1',
    }
  },
})
